#inputID::placeholder {
  color: #343538;
  opacity: 1;
}

.click:hover {
  width: 120px;
  background-color: black;
}
.outroClick {
  width: 50px;
  height: 50px;
  margin: auto;
}
.click {
  height: 50px;
  background-color: red;
  transition: all 0.5s;
}
.logOut:hover {
  background-color: #bdbdbd;
}
.click:hover {
  margin-left: -100px;
}
/* main css for add button  */
#outer {
  width: 80px;
  height: 20px;
  margin: auto;
  position: relative;
  left: 40px;
  top: 10px;
}

#inner {
  height: 35px;
  background-color: #52ae11;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
#inner:hover {
  margin-left: -50px;
}
#outerMob {
  width: 35px;
  height: 25px;
  margin: auto;
  position: relative;
  left: 40px;
  top: 10px;
}

#innerMob {
  height: 35px;
  background-color: #52ae11;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
#innerMob:hover {
}

.pagenumDisplay {
  display: none;
}
.pagination {
  display: flex;
}
.prepage {
  margin-right: 10px;
  background-color: #f6f7f8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 10px;
  width: 40px;
  height: 40px;
}
.nextpage {
  background-color: #f6f7f8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 10px;
  width: 40px;
  height: 40px;
}
.nextpage:hover {
  background-color: #52ae11;
}
.prepage:hover {
  background-color: #52ae11;
}

.hide {
  display: none;
}

.myDIV:hover + .hide {
  display: block;
  color: red;
}
.myDIV:hover {
  display: none;
}
/* .modal {
  opacity: 0.5 !important;
} */

.input {
  border: none;
  outline: none;
}
.input:active {
  border: none;
  outline: none;
}

body {
  background: #fcfcfc;
}

.container {
  width: 100%;
  height: 100vh;
}
.dropdown {
  width: "100%";
  user-select: none;
  position: relative;
}
.dropdown .dropdown-btn {
  padding: 15px 20px;
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
}
.myInput {
  text-align: center;
}
.dropdown .dropdown-content {
  position: absolute;
  top: 130%;
  left: 0;
  padding: 5px;
  background: #fff;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.06);
  font-weight: 500;
  color: #333;
  width: 100%;
  border-radius: 8px;
}
.dropdown .dropdown-content .dropdown-item {
  padding: 7px;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 8px;
}
.dropdown .dropdown-content .dropdown-item:hover {
  background: #f4f4f4;
}
.tooltipModal {
  position: relative;
}

.tooltipModal .tooltiptextModal {
  visibility: hidden;
  width: 280px;
  background-color: #2b5a96;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: -380%;
  margin-left: -68px;
}

.tooltipModal .tooltiptextModal::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2b5a96 transparent;
}

.tooltipModal:hover .tooltiptextModal {
  visibility: visible;
}

.img-wrapper {
  overflow: hidden;
}

img.hover-zoom {
  transition: all 0.3s ease 0s;
  width: 100%;
}

img.hover-zoom:hover {
  transform: scale(1.25);
}
.myList:hover {
  background: "#F6F7F8";
}

.myList:hover {
  background-color: #f6f7f8;
}
